<template>
  <v-list-item
    :disabled="disabled"
    @click="copy"
  >
    <v-list-item-title>
      クリップボードにコピー
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api'

export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const copy = () => {
      const headerRow = props.headers.map(h => h.text).join('\t')

      const dataRows = props.items.map(item => {
        return props.headers.map(h => item[h.value]).join('\t')
      })

      const tableString = [headerRow, ...dataRows].join('\n')

      navigator.clipboard.writeText(tableString)
        .then(() => {
          vm.$toast.info('クリップボードにコピーしました')
        })
        .catch(err => {
          vm.$toast.error('クリップボードへのコピーに失敗しました')
        })
    }

    return {
      copy,
    }
  },
}
</script>
