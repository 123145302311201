<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <v-skeleton-loader
      v-if="isMounting"
      type="table"
    />

    <v-data-table
      v-else
      :loading="isLoading"
      :headers="headers"
      :items="items"
      no-data-text="データがありません"
      loading-text="読込中..."
      :header-props="{ sortByText: 'ソート' }"
      hide-default-footer
      :items-per-page="-1"
    >
      <template #top>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>諸経費</v-toolbar-title>
          <v-spacer />
          <v-btn
            v-if="!readonly"
            color="primary"
            :ripple="false"
            small
            @click="createExpenseRow"
          >
            <v-icon left small v-text="icons.mdiPlus" />
            追加
          </v-btn>
          <v-btn
            icon
            depressed
            :ripple="false"
            :loading="isLoading"
            class="ml-2 mr-1"
            @click="reloadExpenses"
          >
            <v-icon v-text="icons.mdiReload" />
          </v-btn>
          <v-divider
            vertical
            inset
            class="mx-2"
          />
          <v-menu
            bottom
            left
          >
            <template #activator="{ on: menuOn, attrs: menuAttrs }">
              <v-btn
                icon
                v-bind="menuAttrs"
                v-on="menuOn"
              >
                <v-icon v-text="icons.mdiDotsVertical" />
              </v-btn>
            </template>

            <template #default="menu">
              <v-list
                :disabled="isLoading"
                dense
              >
                <v-subheader>操作</v-subheader>
                <v-list-item
                  @click="showTertiaryColumns = !showTertiaryColumns; menu.value = false"
                >
                  <v-list-item-title>
                    {{ showTertiaryColumns ? '詳細分類を非表示' : '詳細分類を表示' }}
                  </v-list-item-title>
                </v-list-item>

                <DataTableCopyListItemBtn
                  :disabled="isLoading"
                  :headers="headers"
                  :items="items"
                />
              </v-list>
            </template>
          </v-menu>
        </v-toolbar>
      </template>

      <template
        v-for="obj in [
          {
            items: primaryLabels,
            key: 'expensePrimaryLabelId',
            placeholder: '選択してください',
            required: true,
          },
          { items: secondaryLabels, key: 'expenseSecondaryLabelId' },
          { items: tertiaryLabels, key: 'expenseTertiaryLabelId' },
          { items: quaternaryLabels, key: 'expenseQuaternaryLabelId' },
        ]"
        #[`item.${obj.key}`]="{ item }"
      >
        <v-combobox
          v-if="!readonly"
          :key="`combobox-${obj.key}-${item.id}`"
          :value="selectedLabelName(item, obj.key)"
          :items="obj.items"
          item-text="attributes.name"
          :placeholder="obj.placeholder"
          :clearable="!obj.required"
          dense
          hide-details
          :menu-props="{ auto: true, offsetY: true }"
          :readonly="readonly"
          @change="onChangeLabel($event, item, obj.key)"
        />
        <v-text-field
          v-else
          :key="`combobox-${obj.key}-${item.id}`"
          :value="selectedLabelName(item, obj.key)"
          dense
          hide-details
          readonly
        />
      </template>

      <template #[`item.amount`]="{ item }">
        <v-text-field
          v-model.number="item.amount"
          type="number"
          dense
          hide-details
          :readonly="readonly"
          @change="debounceUpdateExpense(item, 'amount')"
        >
          <template #prepend-inner>¥</template>
        </v-text-field>
      </template>

      <template #[`item.meta.isNonCash`]="{ item }">
        <v-checkbox
          v-model="item.meta.isNonCash"
          hide-details
          dense
          :readonly="readonly"
          @change="updateExpense(item, { meta: { isNonCash: $event } })"
        />
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          v-if="isNew(item) && !readonly"
          color="primary"
          :ripple="false"
          :disabled="!isValidExpense(item)"
          small
          @click="createExpense(item)"
        >
          保存
        </v-btn>

        <v-btn
          v-if="!isNew(item) && !readonly"
          icon
          text
          :ripple="false"
          @click="destroyExpense(item)"
        >
          <v-icon small v-text="icons.mdiTrashCanOutline" />
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  toRefs,
  computed,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiReload,
  mdiTrashCanOutline,
  mdiPlus,
  mdiDotsVertical,
} from '@mdi/js'
import { debounce } from 'lodash'

import ExpenseApi from '@/api/v2/eod-closing/Expense'
import ExpensePrimaryLabelApi from '@/api/v2/eod-closing/ExpensePrimaryLabel'
import ExpenseSecondaryLabelApi from '@/api/v2/eod-closing/ExpenseSecondaryLabel'
import ExpenseTertiaryLabelApi from '@/api/v2/eod-closing/ExpenseTertiaryLabel'
import ExpenseQuaternaryLabelApi from '@/api/v2/eod-closing/ExpenseQuaternaryLabel'
import useCompInit from '@/views/composable/useCompInit'
import useUserPreferences from '@/views/v2-temp/common/composables/useUserPreferences'
import DataTableCopyListItemBtn from '@/views/v2-temp/common/composables/components/DataTableCopyListItemBtn.vue'

export default {
  components: {
    DataTableCopyListItemBtn,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { date } = toRefs(props)
    const { isLoading, initWith } = useCompInit()
    const { preferences } = useUserPreferences()

    const isMounting = ref(true)
    const expenses = ref([])
    const primaryLabels = ref([])
    const secondaryLabels = ref([])
    const tertiaryLabels = ref([])
    const quaternaryLabels = ref([])
    const labelsRefByKey = {
      expensePrimaryLabelId: primaryLabels,
      expenseSecondaryLabelId: secondaryLabels,
      expenseTertiaryLabelId: tertiaryLabels,
      expenseQuaternaryLabelId: quaternaryLabels,
    }
    const apiByKey = {
      expensePrimaryLabelId: ExpensePrimaryLabelApi,
      expenseSecondaryLabelId: ExpenseSecondaryLabelApi,
      expenseTertiaryLabelId: ExpenseTertiaryLabelApi,
      expenseQuaternaryLabelId: ExpenseQuaternaryLabelApi,
    }

    const isNew = computed(() => item => !item.id)

    const showTertiaryColumns = computed({
      get: () => preferences.value['eod_closing:expenses'].showTertiaryColumns,
      set: val => {
        preferences.value['eod_closing:expenses'].showTertiaryColumns = val
      },
    })

    const headers = computed(() => [
      { text: '主分類', value: 'expensePrimaryLabelId' },
      { text: '副分類', value: 'expenseSecondaryLabelId' },
      ...(showTertiaryColumns.value
        ? [
          { text: '第三分類', value: 'expenseTertiaryLabelId' },
          { text: '第四分類', value: 'expenseQuaternaryLabelId' },
        ] : []
      ),
      { text: '金額', value: 'amount' },
      { text: '非現金', value: 'meta.isNonCash' },
      { text: '', value: 'actions' },
    ])

    const items = computed(() => {
      return expenses.value.map(exp => {
        const attr = exp.attributes || {}
        const meta = exp.meta || {}
        const row = {
          id: attr.id,
          expensePrimaryLabelId: attr.expensePrimaryLabelId || null,
          expenseSecondaryLabelId: attr.expenseSecondaryLabelId || null,
          expenseTertiaryLabelId: attr.expenseTertiaryLabelId || null,
          expenseQuaternaryLabelId: attr.expenseQuaternaryLabelId || null,
          amount: attr.amount || 0,
          meta: {
            isNonCash: meta.isNonCash || false,
            tempId: meta.tempId,
          },
        }

        return row
      })
    })

    const selectedLabelName = computed(() => (item, key) => {
      const labelsRef = labelsRefByKey[key]
      if (!labelsRef) return ''

      return labelsRef.value.find(l => Number(l.attributes.id) === Number(item[key]))?.attributes?.name
    })

    const isValidExpense = computed(() => item => {
      const {
        amount,
        expensePrimaryLabelId,
      } = item

      return typeof amount === 'number' && amount !== 0 && expensePrimaryLabelId
    })

    /**
     * API: 諸経費一覧取得
     */
    const getExpenses = async () => {
      isLoading.value = true
      try {
        const res = await ExpenseApi.getExpenses(date.value)
        if (res.data.status === 'success') {
          expenses.value = res.data.data.data
        }
      } finally {
        isLoading.value = false
      }
    }

    const getExpenseLabels = async () => {
      isLoading.value = true
      try {
        const [
          resPrimary,
          resSecondary,
          resTertiary,
          resQuaternary,
        ] = await Promise.all([
          ExpensePrimaryLabelApi,
          ExpenseSecondaryLabelApi,
          ExpenseTertiaryLabelApi,
          ExpenseQuaternaryLabelApi,
        ].map(api => api.getLabels()))
        const resArr = [resPrimary, resSecondary, resTertiary, resQuaternary]
        resArr.forEach((res, idx) => {
          if (res.data.status === 'success') {
            switch (idx) {
              case 0:
                primaryLabels.value = res.data.data.data
                break
              case 1:
                secondaryLabels.value = res.data.data.data
                break
              case 2:
                tertiaryLabels.value = res.data.data.data
                break
              case 3:
                quaternaryLabels.value = res.data.data.data
                break
              default:
                break
            }
          }
        })
      } finally {
        isLoading.value = false
      }
    }

    /**
     * データテーブルの行追加 (新規Expenseの作成)
     * - ここでは即座にAPIで作成するか、あるいはローカルだけに追加するか選べる
     * - 例では即API呼び出し→テーブル再取得 にしている
     */
    const buildEmptyExpense = () => ({
      id: null,
      attributes: {
        expensePrimaryLabelId: null,
        expenseSecondaryLabelId: null,
        expenseTertiaryLabelId: null,
        expenseQuaternaryLabelId: null,
        amount: 0,
      },
      meta: {
        isNonCash: false,
        tempId: `temp_${Date.now()}_${Math.floor(Math.random() * 1e9)}`,
      },
    })
    const createExpenseRow = async () => {
      expenses.value = [buildEmptyExpense(), ...expenses.value]
    }
    const createExpense = async item => {
      try {
        isLoading.value = true

        const {
          amount,
          expensePrimaryLabelId,
          expenseSecondaryLabelId,
          expenseTertiaryLabelId,
          expenseQuaternaryLabelId,
          meta,
        } = item

        const res = await ExpenseApi.createExpense({
          businessDate: date.value,
          amount,
          expensePrimaryLabelId,
          expenseSecondaryLabelId,
          expenseTertiaryLabelId,
          expenseQuaternaryLabelId,
          meta: {
            isNonCash: meta.isNonCash,
          },
        })

        if (res.data.status === 'success') {
          const created = res.data.data.data
          const idx = expenses.value.findIndex(e => e.meta.tempId === item.meta.tempId)
          if (idx >= 0) {
            expenses.value.splice(idx, 1, created)
          }
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        isLoading.value = false
      }
    }

    /**
     * フィールド更新(部分更新) - debounce
     */
    const updateExpense = async (item, params = {}) => {
      if (!item.id) return

      const {
        amount,
        expensePrimaryLabelId,
        expenseSecondaryLabelId,
        expenseTertiaryLabelId,
        expenseQuaternaryLabelId,
      } = item

      try {
        const res = await ExpenseApi.updateExpense(item.id, {
          amount,
          expensePrimaryLabelId,
          expenseSecondaryLabelId,
          expenseTertiaryLabelId,
          expenseQuaternaryLabelId,
          ...params,
        })
        if (res.data.status === 'success') {
          const updated = res.data.data.data
          const idx = expenses.value.findIndex(e => e.id === updated.id)
          if (idx >= 0) {
            expenses.value.splice(idx, 1, updated)
          }
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      }
    }
    const debounceUpdateExpense = debounce((item, key) => {
      if (!item.id) return

      const params = { [`${key}`]: item[key] }
      updateExpense(item, params)
    }, 500)

    /**
     * 新規ラベル作成
     */
    const createNewLabel = async (key, name) => {
      const api = apiByKey[key]
      if (!api) return null

      try {
        const res = await api.createLabel(name)
        if (res.data.status === 'success') {
          const labelsRef = labelsRefByKey[key]
          if (!labelsRef) return null

          const label = res.data.data.data
          labelsRef.value = [...labelsRef.value, label]

          return label
        }
      } catch (e) {
        console.error(e)
      }

      return null
    }
    /**
     * コンボボックス（各ラベル）の @change ハンドラ
     * - ラベルobj か 文字列(=新規作成候補) かを判定
     * - 新規なら createNewLabel(key, label)
     * - その後 expense を更新
     */
    const onChangeLabel = async (label, item, key) => {
      if (typeof label === 'string') { // ラベルを新規作成
        const newLabel = await createNewLabel(key, label)
        if (newLabel) {
          // eslint-disable-next-line no-param-reassign
          item[key] = newLabel.id

          return debounceUpdateExpense(item, key)
        }
      } else if (label === null) { // 選択解除
        // eslint-disable-next-line no-param-reassign
        item[key] = null

        return debounceUpdateExpense(item, key)
      }

      // eslint-disable-next-line no-param-reassign
      item[key] = label.id

      return debounceUpdateExpense(item, key)
    }

    /**
     * 行削除
     */
    const destroyExpense = async item => {
      if (!item.id) return

      try {
        isLoading.value = true
        const res = await ExpenseApi.destroyExpense(item.id)
        if (res.data.status === 'success') {
          expenses.value = expenses.value.filter(e => Number(e.id) !== Number(item.id))
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        isLoading.value = false
      }
    }

    const setInitialExpenses = () => {
      if (expenses.value.length === 0 && !props.readonly) {
        expenses.value = [...Array(10)].map(_ => buildEmptyExpense())
      }
    }

    const reloadExpenses = async () => {
      await Promise.all([
        getExpenses(),
        getExpenseLabels(),
      ])
      setInitialExpenses()
    }

    onMounted(async () => {
      await initWith([
        getExpenses(),
        getExpenseLabels(),
      ])
      setInitialExpenses()
      isMounting.value = false
    })

    return {
      // refs
      isMounting,
      expenses,
      primaryLabels,
      secondaryLabels,
      tertiaryLabels,
      quaternaryLabels,
      showTertiaryColumns,

      // computed
      selectedLabelName,
      items,
      headers,
      isNew,
      isValidExpense,

      // methods
      createExpenseRow,
      createExpense,
      updateExpense,
      destroyExpense,
      reloadExpenses,
      onChangeLabel,
      createNewLabel,
      debounceUpdateExpense,

      // from useCompInit
      isLoading,

      // icons
      icons: {
        mdiReload,
        mdiTrashCanOutline,
        mdiPlus,
        mdiDotsVertical,
      },
    }
  },
}
</script>
