/* eslint-disable no-param-reassign */
import { toRefs, ref, computed } from '@vue/composition-api'
import { judgeType, interpretValue } from '@/utils/typeUtils'

export default (props, emit) => {
  const { aggregation, loading } = toRefs(props)

  const justSaved = ref(false)

  const isUserAggregation = computed(() => /(UserReport|UserAggregation)$/.test(aggregation.value.attributes.type))
  const isBooleanItem = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId] === 'boolean')
  const isNumberItem = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId] === 'number')
  const isNew = computed(() => (item, businessRuleId) => !item.meta.businessRuleResultIds[businessRuleId] && item[businessRuleId] !== null)
  const isNotEditable = computed(() => (item, businessRuleId) => loading.value || !item.meta.editable[businessRuleId])
  const inputType = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId])
  const numberText = computed(() => (item, businessRuleId) => item[businessRuleId].toLocaleString('ja-JP'))

  /**
   * テーブルのヘッダーを構築する関数
   *
   * type BusinessRuleId = number
   *
   * interface BusinessRuleData {
   *   id: string;
   *   type: string;
   *   attributes: {
   *     id: BusinessRuleId;
   *     name: string;
   *     description: string | null;
   *     dependentBusinessRuleIds?: BusinessRuleId[]
   *   };
   * };
   *
   * interface Header { text: string; value: string; }
   * @param {Object} BusinessRuleData[]
   * @returns {Object} Header[]
   */
  const buildTableHeaders = businessRulesData => {
    return businessRulesData.map(data => {
      return {
        text: data.attributes.name,
        value: data.id,
        // autoにしてもcalculate-widthsを指定してもコンテンツに応じて幅がセットされないので一旦キメで
        maxWidth: '160px',
        minWidth: '160px',
        width: '160px',
      }
    })
  }

  const buildTableItem = resultsData => {
    const item = {
      meta: {
        businessRuleResultIds: {},
        dependentResources: {},
        dependentBusinessRuleIds: {},
        editable: {},
        _originalValues: {}, // 初期化時の値を保存
        _inputTypes: {}, // 初期化時の値を保存
        get originalValues() { // 初期化時の値を読み取り専用で返す
          return { ...this._originalValues }
        },
        get inputTypes() { // 初期化時の値を読み取り専用で返す
          return { ...this._inputTypes }
        },
      },
    }

    resultsData.forEach(result => {
      const {
        businessRuleId,
        businessRuleResultId,
        value,
        dependentResources,
        dependentBusinessRuleIds,
        editable,
      } = result.attributes

      if (Array.isArray(value)) return
      if (value !== null && typeof value === 'object') return

      const interpretedValue = interpretValue(value)
      item[businessRuleId] = interpretedValue
      item.meta.businessRuleResultIds[businessRuleId] = businessRuleResultId
      item.meta.dependentResources[businessRuleId] = dependentResources
      item.meta.dependentBusinessRuleIds[businessRuleId] = dependentBusinessRuleIds
      item.meta.editable[businessRuleId] = editable

      // originalValues と inputTypes に初期値を設定
      item.meta._originalValues[businessRuleId] = interpretedValue
      item.meta._inputTypes[businessRuleId] = judgeType(value)
    })

    return item
  }

  const aggregationTable = computed(() => {
    const {
      id,
      name,
      description,
      businessRules,
      contexts,
      type,
    } = aggregation.value.attributes

    const businessRuleHeaders = buildTableHeaders(businessRules.data)
    const items = contexts.data.map(context => {
      const {
        contextType,
        id: contextId,
        name: contextName,
        results,
      } = context.attributes

      return {
        contextType,
        id: contextId,
        name: contextName,
        ...buildTableItem(results.data),
      }
    })

    const headers = [...businessRuleHeaders]
    if (isUserAggregation.value) {
      headers.unshift({
        text: 'ユーザー',
        value: 'name',
        maxWidth: '160px',
        minWidth: '160px',
        width: '160px',
        class: 'fixed-cell',
        cellClass: 'fixed-cell',
      })
    }

    return {
      id,
      name,
      description,
      businessRuleHeaders,
      headers,
      items,
      type,
    }
  })

  const saveResultValue = (item, businessRuleId, resultValue, menu) => {
    justSaved.value = true
    menu.value = false
    const { id: contextId, contextType } = item

    emit('save', {
      aggregationId: aggregation.value.attributes.id,
      contextId,
      contextType,
      businessRuleId,
      resultValue,
    })
  }

  const onMenuInput = (open, item, businessRuleId) => {
    if (open) return

    // 保存時に閉じた時は何もしない
    if (justSaved.value) {
      justSaved.value = false

      return
    }

    // escや外側クリックなどで編集モードを抜ける場合は元に戻す
    item[businessRuleId] = item.meta.originalValues[businessRuleId]
  }

  return {
    aggregationTable,
    isUserAggregation,
    isBooleanItem,
    isNumberItem,
    isNew,
    isNotEditable,
    inputType,
    numberText,
    saveResultValue,
    onMenuInput,
  }
}
